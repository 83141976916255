/**
 * @param {number} unitAmount - default stripe unit amount. e.g. 1000 = $10.00
 * @param {string} currency - ISO 4217 currency code, 3 letters
 * @param {number} numberDecimalPlaces - number of decimal place to return. Default: 0
 * @return {string} formatted currency
 */
export function formatCurrency(
  unitAmount: number,
  currency: string,
  numberDecimalPlaces: number = 0
): string {
  try {
    return formatCurrencyInternal(unitAmount, currency, numberDecimalPlaces);
  } catch (e) {
    return formatCurrencyInternal(unitAmount, "usd", numberDecimalPlaces);
  }
}

function formatCurrencyInternal(
  value: number,
  currency: string,
  numberDecimalPlaces: number
): string {
  const formatter = new Intl.NumberFormat(undefined, {
    style: "currency",
    currency,
    minimumFractionDigits: numberDecimalPlaces,
  });

  const multiplier = currencyMultiplier(currency);

  return formatter.format(value / multiplier);
}

// https://stripe.com/docs/currencies#three-decimal
const threeDecimalCurrencyCodes = ["bhd", "jod", "kwd", "omr", "tnd"];
// https://stripe.com/docs/currencies#zero-decimal
const zeroDecimalCurrencyCodes = [
  "bif",
  "clp",
  "djf",
  "gnf",
  "jpy",
  "kmf",
  "krw",
  "mga",
  "pyg",
  "rwf",
  "ugx",
  "vnd",
  "vuv",
  "xaf",
  "xof",
  "xpf",
];

export function currencyMultiplier(code: string): 1 | 100 | 1000 {
  if (threeDecimalCurrencyCodes.includes(code)) {
    return 1000;
  }

  if (zeroDecimalCurrencyCodes.includes(code)) {
    return 1;
  }

  return 100;
}
